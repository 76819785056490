//color system
$primary: #0b0b45;
$secondary:#74788d;
//$success: #2ab57d;
$success: #279568;
$info: #4ba6ef;
$warning: #ffbf53;
$warning-light: #fff3cd;
$warning-border: #ffeeba;
$danger:#d32f2f;
$danger-dark: #ac2525;
//$danger: #fd625e;
//$danger-dark: #ec534d;
$blue:#5156be;
$indigo:#564ab1;
$purple:#6f42c1;
$grey:#74788d;
$grey-dark:#343a40;
// $grey-100: #f8f9fa; --MD
$grey-100: #f1f6fe;
$grey-200: #e9e9ef;
$grey-300: #cbcbda;
$grey-400: #ced4da;
$grey-500: #adb5bd;
$grey-600: #74788d;
$grey-700: #495057;
$grey-800: #343a40;
$grey-900: #212529;
$teal: #050505;
$cyan: #4ba6ef;
$white: #fff;



//font system
$body-font-size: 15px;
$body-font-weight: 400;
$body-line-height: 1.5;

//other colors
$sidebar-bg: #fbfaff;
$sidebar-menu-item-color: #545a6d;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #545a6d;
$sidebar-menu-item-icon-effact-color: rgba(84,90,109,0.2);
$sidebar-menu-item-hover-color: $primary;
$sidebar-menu-item-hover-effact-color: rgba(81,86,190,0.2);
$sidebar-menu-item-active-color: $primary;
$sidebar-menu-item-active-effact-color: rgba(81,86,190,0.2);
$sidebar-border-color: $grey-300; //$grey-200;
$sidebar-menu-item-active-bg-color: #f3f8fb;
$sidebar-menu-item-active-bg-color-dark: #293552;
$sidebar-alert: rgba(81,86,190,0.1);

// $sidebar-dark-bg: #2C302E;
// $sidebar-dark-menu-item-color: #99a4b1;
// $sidebar-dark-menu-sub-item-color: #858d98;
// $sidebar-dark-menu-item-icon-color: #858d98;
// $sidebar-dark-menu-item-hover-color: $white);
// $sidebar-dark-menu-item-active-color: $white);

$header-bg: $white;
$header-item-color: #555b6d;

// $header-dark-bg: $white);
// $header-dark-item-color: #e9ecef;

$topbar-search-bg: #f3f3f9;
//$topbar-dark-search-bg: #363a38;
$search-width: 0px;
$search-border-color: $grey-300; //$grey-200;
$box-shadow: 0 0.25rem 0.75rem rgba(18,38,63,.08);
$footer-bg: $white;
$footer-color: $secondary;
$topnav-bg: #fbfaff;
$topnav-item-color: $grey-400;
$topnav-item-color-active: $white;
$menu-item-color: #7b8190;
$menu-item-active-color: #5b73e8;
$body-bg: $white;
$body-color: $grey-700;
$body-color-rgb: 73,80,87;
$apex-grid-color: $grey-100;
$display-none: none;
$display-block: block;
$card-title-desc: $secondary;
$light: $grey-200;
$light-rgb: 233,233,239;
$dark: $grey-900;
$dark-rgb: 33,37,41;
$text-muted: $secondary;
$link-color: $blue;
$link-hover-color: $blue;
$border-color: $grey-200;
$dropdown-bg: $white;
$dropdown-link-color: $grey-900;
$dropdown-link-hover-color: #1f2327;
$dropdown-link-hover-bg: $grey-100;
$dropdown-border-width: 0px;
$card-bg: $white;
$card-cap-bg: $white;
$card-border-color: $grey-200;
$card-logo-dark: block;
$card-logo-light: none;
$modal-bg: $white;
$modal-content-bg: $white;
$modal-content-border-color: $grey-300;
$nav-tabs-link-active-color: $grey-700;
$nav-tabs-link-active-bg: $white;
$accordion-button-active-color: #494dab;
$accordion-bg: $white;
$accordion-button-bg: $grey-200;
$table-color: $grey-700;
$boxed-body-bg: #f0f0f0;
$progress-bg: $grey-200;
$toast-background-color: rgba(255, 255, 255, 0.85);
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-header-border-color: rgba(0, 0, 0, 0.05);
$list-group-hover-bg: $grey-100;
$popover-bg: $white;

$pagination-hover-bg: $grey-200;

$input-bg: $white;
$input-group-addon-border-color: $grey-400;
$input-border: $grey-400;
$input-border-color: $grey-400;
$input-focus-border: #a8abdf;
$input-disabled-bg: $grey-200;
$input-placeholder-color: $secondary;
$input-group-addon-bg: $grey-200;
$input-check-border: $input-border;


:export {
    colorPrimary: $primary;
    colorSecondary: $secondary;
    colorSuccess: $success;
    colorInfo: $info;
    colorWarning: $warning;
    colorDanger: $danger;
    colorBlue: $blue;
    colorIndigo: $indigo;
    colorPurple:$purple;
    colorGrey: $grey;
    colorGreyDark: $grey-dark;
    colorGrey100: $grey-100;
    colorGrey200: $grey-200;
    colorGrey300: $grey-300;
    colorGrey400: $grey-400;
    colorGrey500: $grey-500;
    colorGrey600: $grey-600;
    colorGrey700: $grey-700;
    colorGrey800: $grey-800;
    colorGrey900: $grey-900;
    colorTeal: $teal;
    colorCyan: $cyan;
    colorWhite: $white;



    //font system
    bodyFontSize: $body-font-size;
    bodyFontWeight: $body-font-weight;
    bodyLineHeight: $body-line-height;

    //other colors
    sidebarBg: $sidebar-bg;
    sidebarMenuItemColor:  $sidebar-menu-item-color;
    sidebarMenuSubItemColor: $sidebar-menu-sub-item-color;
    sidebarMenuItemIconColor: $sidebar-menu-item-icon-color;
    sidebarMenuItemIconEffactColor: $sidebar-menu-item-icon-effact-color;
    sidebarMenuItemHoverColor: $sidebar-menu-item-hover-color;
    sidebarMenuItemHoverEffactColor: $sidebar-menu-item-hover-effact-color;
    sidebarMenuItemActiveColor: $sidebar-menu-item-active-color;
    sidebarMenuItemActiveEffactColor: $sidebar-menu-item-active-effact-color;
    sidebarBorderColor: $sidebar-border-color;
    sidebarMenuItemActiveBgColor: $sidebar-menu-item-active-bg-color;
    sidebarMenuItemActiveBgColorDark:$sidebar-menu-item-active-bg-color-dark;
    sidebarAlert: $sidebar-alert;

    // $sidebar-dark-bg: #2C302E;
    // $sidebar-dark-menu-item-color: #99a4b1;
    // $sidebar-dark-menu-sub-item-color: #858d98;
    // $sidebar-dark-menu-item-icon-color: #858d98;
    // $sidebar-dark-menu-item-hover-color: $white);
    // $sidebar-dark-menu-item-active-color: $white);

    headerBg: $header-bg;
    headerItemColor: $header-item-color;

    // $header-dark-bg: $white);
    // $header-dark-item-color: #e9ecef;

    topbarSearchBg:$topbar-search-bg;
    //$topbar-dark-search-bg: #363a38;
    searchWidth: $search-width;
    searchBorderColor: $search-border-color;
    boxShadow: $box-shadow;
    footerBg: $footer-bg;
    footerColor: $footer-color;
    topnavBg: $topnav-bg;
    topnavItemColor: $topnav-item-color;
    topnavItemColorActive: $topnav-item-color-active;
    menuItemColor: $menu-item-color;
    menuItemActiveColor: $menu-item-active-color;
    bodyBg: $body-bg;
    bodyColor: $body-color;
    modyColorRgb: $body-color-rgb;
    gridColor: $apex-grid-color;
    displayNone: $display-none;
    displayBlock: $display-block;
    cardTitleDesc: $card-title-desc;
    light: $light;
    lightRgb: $light-rgb;
    dark: $dark;
    darkRgb: $dark-rgb;
    textMuted: $text-muted;
    linkColor: $link-color;
    linkHoverColor: $link-hover-color;
    borderColor: $border-color;
    dropdownBg: $dropdown-bg;
    dropdownLinkColor: $dropdown-link-color;
    dropdownLinkHoverColor:$dropdown-link-hover-color;
    dropdownLinkHoverBg: $dropdown-link-hover-bg;
    dropdownBorderWidth: $dropdown-border-width;
    cardBg: $card-bg;
    cardCapBg: $card-cap-bg;
    cardBorderColor:  $card-border-color;
    cardLogoDark: $card-logo-dark;
    cardLogoLight: $card-logo-light;
    modal-bg:$modal-bg;
    modal-content-bg: $modal-content-bg;
    modal-content-border-color: $modal-content-border-color;
    nav-tabs-link-active-color: $nav-tabs-link-active-color;
    nav-tabs-link-active-bg: $nav-tabs-link-active-bg;
    accordion-button-active-color:$accordion-button-active-color;
    accordion-bg: $accordion-bg;
    accordion-button-bg: $accordion-button-bg;
    Table-color: $table-color;
    Boxed-body-bg: $boxed-body-bg;
    progress-bg: $progress-bg;
    Toast-background-color: $toast-background-color;
    Toast-border-color: $toast-border-color;
    Toast-header-border-color: $toast-header-border-color;
    list-group-hover-bg: list-group-hover-bg;
    popover-bg: $popover-bg;

    pagination-hover-bg: $pagination-hover-bg;

    input-bg: $input-bg;
    input-group-addon-border-color: $input-group-addon-border-color;
    input-border: $input-border;
    input-border-color: $input-border-color;
    input-focus-border: $input-focus-border;
    input-disabled-bg: $input-disabled-bg;
    input-placeholder-color: $input-placeholder-color;
    input-group-addon-bg: $input-group-addon-bg;
    input-check-border: $input-check-border;
  }